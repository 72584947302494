import { useMemo, useState } from "react";
import { gql, ApolloClient, useApolloClient } from "@apollo/client";

const unlinkGroupMutation = gql`
  mutation RemoveGroupAnimal($animalId: ID!, $groupId: ID!) {
    removeGroupAnimal(animalId: $animalId, groupId: $groupId) {
      access
    }
  }
`;

const unlinkGroup = async (
  apollo: ApolloClient<any>,
  animalId: string,
  groupId: string
) => {
  const result = await apollo.mutate({
    mutation: unlinkGroupMutation,
    variables: {
      animalId,
      groupId,
    },
  });

  const ret = result.data?.removeGroupAnimal;
  return ret;
};

export const useMultiRemoveGroupAnimal = (animalId: string) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0.0);
  const [cancel, setCancel] = useState(false);
  const apollo = useApolloClient();

  const execute = useMemo(
    () =>
      async (
        groupIds: string[],
        onDone: () => void,
        onError: (error: string) => void
      ) => {
        setLoading(true);
        setCancel(false);
        setProgress(0.0);

        try {
          for (let c = 0, len = groupIds.length; c < len; c++) {
            if (cancel) {
              break;
            }

            const g = groupIds[c];
            await unlinkGroup(apollo, animalId, g);
            setProgress((c / groupIds.length) * 100);
          }
          setLoading(false);
          setProgress(100);
          onDone();
        } catch (e) {
          onError("Failed to unlink groups");
        }
      },
    [animalId, cancel, apollo]
  );

  return { loading, execute, progress, setCancel };
};
