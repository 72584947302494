import {
  Alert,
  AppBar,
  Toolbar,
  Chip,
  Link,
  Skeleton,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { CloseSharp, Refresh, LinkOff } from "@mui/icons-material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAnimalGroups } from "../../hooks/animal/use-animal-groups";
import { getTagColor } from "../Implants/ImplantCustomTags";
import { useMultiRemoveGroupAnimal } from "../../hooks/group/use-multi-remove-group-animals";
import { ReactNodeLike } from "prop-types";
import { LinkGroupToAnimalButton } from "./LinkGroupToAnimalButton";

export function QuickSearchToolbar(props: {
  animalId: string;
  numRowsSelected?: number;
  onDelete?: () => void;
  onReload?: () => void;
  buttons: ReactNodeLike;
}) {
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        p: 0.5,
        pb: 0,
        pt: 1,
        flex: 1,
      }}
    >
      <Stack direction="row" justifyContent="start">
        {!!props.onReload && (
          <GridToolbarContainer sx={{ flex: 2 }}>
            <Button
              size="small"
              onClick={() => {
                props.onReload && props.onReload();
              }}
              aria-label="Reload Data"
              startIcon={<Refresh />}
            >
              Reload
            </Button>
          </GridToolbarContainer>
        )}
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
        {props.buttons}
      </Stack>
      <Stack direction="row" justifyContent="end" sx={{ flex: 1, pr: 1 }}>
        <GridToolbarContainer>
          <LinkGroupToAnimalButton animalId={props.animalId} />
        </GridToolbarContainer>
        {!!props.numRowsSelected && !!props.onDelete && (
          <GridToolbarContainer>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                props.onDelete && props.onDelete();
              }}
              aria-label="unlink selected groups"
            >
              <LinkOff />
              <Typography color="inherit" component="span" variant="subtitle2">
                Unlink {props.numRowsSelected} group
                {props.numRowsSelected > 1 ? "s" : ""}
              </Typography>
            </Button>
          </GridToolbarContainer>
        )}
      </Stack>
    </Stack>
  );
}

export const ImplantGroups: FC<{ animalId: string; animalName?: string }> = ({
  animalId,
  animalName,
}) => {
  const navigate = useNavigate();
  const { loading, groups, refresh } = useAnimalGroups(animalId);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [removing, setRemoving] = useState(false);
  const [error, setError] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const { execute } = useMultiRemoveGroupAnimal(animalId);

  const handleConfirmDelete = async () => {
    setRemoving(true);
    setCancelling(false);
    setError("");

    await execute(
      selectedRows,
      () => {
        if (!cancelling && error !== "") {
          setError("");
          setRemoving(false);
        } else {
          refresh();
          handleCloseDialog();
        }
        setRemoving(false);
        setSelectedRows([]);
      },
      (error) => {
        setError(error);
      }
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteDialogOpen = () => {
    setCancelling(false);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setCancelling(true);
    setRemoving(false);
    setError("");
    handleCloseDialog();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Group Name",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2" sx={{ cursor: "pointer" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "customTags",
      headerName: "Tags from group",
      flex: 2.5,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.value?.map((p: any) => (
              <Chip
                label={p}
                size="small"
                sx={{
                  marginRight: 1,
                  backgroundColor: getTagColor(p),
                  color: "white",
                }}
              />
            ))}
          </>
        );
      },
    },
    {
      field: "org",
      headerName: "Organisation",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link href={`/orgs/${params.value?.orgId}`} underline="none">
            <Typography variant="body2" sx={{ cursor: "pointer" }}>
              {params.value?.name}
            </Typography>
          </Link>
        );
      },
    },
  ];

  if (loading) {
    return <Skeleton variant="rectangular" height="30rem" sx={{ m: 3 }} />;
  }

  return (
    <Stack height="100%">
      {
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          sx={{ width: "100%" }}
        >
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "120%",
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseDialog}
                  disabled={removing}
                  aria-label="close"
                >
                  <CloseSharp />
                </IconButton>

                <DialogTitle> Confirm Unlink Groups </DialogTitle>
              </Toolbar>
            </AppBar>

            {!removing && (
              <div>
                <DialogContent>
                  This will unlink the selected group
                  {selectedRows.length > 1 ? "s" : ""} from this implant.{" "}
                </DialogContent>
                <DialogContent>
                  Are you sure you want to unlink {selectedRows.length} selected
                  group{selectedRows.length > 1 ? "s" : ""}?
                </DialogContent>
              </div>
            )}
            {removing && (
              <div>
                <DialogContent sx={{ p: 2 }}>
                  <Typography color="error" variant="h5">
                    Unlinking Groups
                  </Typography>
                </DialogContent>
              </div>
            )}

            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      handleCancelDelete();
                    }}
                  >
                    Ok
                  </Button>
                }
              >
                {`An error occurred: ${error}`}
              </Alert>
            )}

            {error === "" && (
              <DialogActions style={{ justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => handleCancelDelete()}
                  disabled={cancelling}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={removing}
                  onClick={() => handleConfirmDelete()}
                >
                  Unlink {selectedRows.length} group
                  {selectedRows.length > 1 ? "s" : ""}
                </Button>
              </DialogActions>
            )}
          </Paper>
        </Dialog>
      }

      <DataGrid
        disableSelectionOnClick
        hideFooter
        columns={columns}
        rows={groups.map((group) => ({
          key: group?.group?.groupId,
          access: group?.access,
          customTags: group.customTags,
          org: group?.group?.organisation,
          ...group?.group,
        }))}
        getRowId={(row: any) => `${row.groupId}|${row.name}`}
        components={{ Toolbar: QuickSearchToolbar }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        onSelectionModelChange={(selection) => {
          let selected = selection.map((row) =>
            groups.find(
              (group) => group.group.groupId === row.toString().split("|")[0]
            )
          );

          let selectMap = selected.map(
            (groupAnimal) => groupAnimal!.group.groupId
          );

          console.log(selectMap);
          setSelectedRows(selectMap);
        }}
        checkboxSelection
        onCellClick={(params: GridCellParams) => {
          if (params.field === "name") {
            navigate(
              { pathname: `/groups/${params.id.toString().split("|")[0]}` },
              { replace: false }
            );
          }
        }}
        componentsProps={{
          toolbar: {
            animalId: animalId,
            animalName: animalName,
            numRowsSelected: selectedRows.length,
            onDelete: () => handleDeleteDialogOpen(),
            buttons: [
              <GridToolbarContainer sx={{ flex: 2 }}></GridToolbarContainer>,
            ],
          },
        }}
      />
    </Stack>
  );
};
