export function preprocessJSONString(jsonString: string): string {
  const regex = /(['"])?([a-zA-Z0-9_]+)(['"])?:/g;
  const replacedString = jsonString.replace(regex, (match, p1, p2, p3) => {
    // Check if the match already has quotes or if it is a number
    if (p1 || !isNaN(parseFloat(p2))) {
      return match; // Return the match as it is
    } else {
      return `"${p2}":`; // Add quotes around the string value
    }
  });
  return replacedString;
}

export function chartValueFormat(
  value: any,
  decimalPlaces: number = 2,
  unit: string = "",
  defaultString: string = ""
) {
  if (value == null) {
    return "NaN";
  }
  const val = parseFloat(value.toString());

  return val.toFixed(decimalPlaces) + unit;
}

export function numberFormat(
  value?: number,
  decimalPlaces: number = 2,
  unit: string = "",
  defaultString: string = "-"
) {
  if (value == null) {
    return defaultString;
  }
  return value.toFixed(decimalPlaces) + unit;
}

export function formatRelativeDate(date?: Date | string) {
  if (date == null) {
    return "-";
  }
  if (typeof date == "string") date = new Date(date);

  const now = Math.floor(Date.now() / 1000);
  const seconds = Math.floor(date.getTime() / 1000);
  const diff = Math.abs(now - seconds);
  const ago = now > seconds;

  if (diff < 5) {
    return "now";
  }
  if (diff < 100) {
    return `${diff} seconds ${ago ? "ago" : "from now"}`;
  }
  if (diff < 100 * 60) {
    return `${Math.floor(diff / 60)} minutes ${ago ? "ago" : "from now"}`;
  }
  if (diff < 50 * 60 * 60) {
    return `${Math.floor(diff / (60 * 60))} hours ${ago ? "ago" : "from now"}`;
  }
  return `${Math.floor(diff / (24 * 60 * 60))} days and ${Math.floor(
    (diff % (24 * 60 * 60)) / (60 * 60)
  )} hours  ${ago ? "ago" : "from now"}`;
}

export function formatDate(date?: Date | string) {
  if (date == null) {
    return "-";
  }
  if (typeof date == "string") date = new Date(date);

  return date.toLocaleString(navigator?.language);
}

export function capitalizeFirst(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function formatSexString(str: String) {
  if (str == null) {
    return "";
  }
  switch (str) {
    case "male_neutered":
      return "Male Neutered";
    case "female_spayed":
      return "Female Spayed";
    case "male":
      return "Male";
    case "female":
      return "Female";
    default:
      return "-";
  }
}

export function formatReadingType(str: String) {
  if (str == null) {
    return "";
  }
  switch (str) {
    case "heartRate":
      return "Heart Rate";
    case "temperature":
      return "Temperature";
    case "hrv":
      return "Heart Rate Variability";
    case "batteryPercent":
      return "Battery Charge";
    default:
      return str;
  }
}

export function monthIndexToString(index: number) {
  if (index == null) {
    return "";
  }
  switch (index) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return "?";
  }
}

export const readingUnit = (type: string) => {
  switch (type) {
    case "temperature":
      return "° C";
    case "heartRateVariance":
    case "hrv":
      return " ms";
    case "batteryPercent":
      return "%";
    case "heartRate":
      return " bpm";
    default:
      return "";
  }
};
